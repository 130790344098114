.stackingMathContainer {
  font-family: serif;
  font-size: 2.5rem;
  letter-spacing: 0.5rem;
  text-align: right;
  width: 250px;
  height: 250px;
  position: relative;
  overflow: hidden;
  padding: 0;
}

.stackingQuestion {
  position: relative;
}

.stackingMathContainer .stackingMath {
  position: absolute;
  bottom: 0.2rem;
  right: 10%;
  z-index: 0;
}

.stackingMathContainer .drawingCanvas {
  border-bottom: 2px solid gray;
  z-index: 1;
  position: relative;
}

.clearCanvas {
  display: flex;
  flex-direction: column;
}