#draftCanvas {
  cursor: crosshair;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 200px;
}

#canvas {
  width: 200px;
  height: 200px;
}

.questionContainer {
  width: 66%;
  padding-left: 10px;
  display: block;
  position: relative;
}

#question {
  font-size: 3.5em;
  text-align: right;
  position: relative;
}

#canvasContainer {
  display: flex;
  align-items: end;
  justify-content: center;
  background: white;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 25px;
  z-index: 1;
  box-shadow: 0px 2px 5px black;
  background-image: linear-gradient(90deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px), linear-gradient(#eee .1em, transparent .1em);
  background-size: 100% 1.65em;
}

#controllerContainer {
  display: flex;
  padding: 10px;
  justify-content: space-around;
  z-index: 1;
  margin: auto;
  gap: 2%;
}

#controllerContainer button {
  padding: .5em .75em;
  border-radius: 10em;
  font-weight: bold;
  border: 1px solid black;
  font-size: 1.5em;
  box-shadow: 0px 3px black;
  position: relative;
}

#controllerContainer button:active {
  bottom: -2px;
  box-shadow: 0 1px black;
}

#controllerDone {
  display: flex;
  gap: 10px;
}