.coinQuestion {
  display: flex;
  align-items: center;
  gap: 0.5em;
  flex-direction: column;
}

.coinView {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: .3em;
}