.card {
  width: 3rem;
  height: 4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  perspective: 9rem;
}

.card .card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s ease-in-out;
  transform-style: preserve-3d;
  box-shadow: 0 0 1px;
  border-radius: .2rem;
}

.card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-back,
.card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  border-radius: .2rem;
}

.card-back {
  background: url('/public/assets/pokemonCardBack.png') no-repeat center;
  background-size: cover;
  color: black;
  border: 2px solid #000000;
  box-sizing: border-box;
}

.card-front {
  background-color: #fff;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
  box-shadow: 0px 1px 3px gray;
}

.card-front .frontImage {
  width: 70%;
}