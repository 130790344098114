#configContainer {
  display: flex;
  line-height: 3em;
  margin: 20px auto;
  gap: 1em;
  z-index: 1;
  flex-wrap: wrap;
  justify-content: center;
}

.toggle {
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 1em;
  margin: 0 5px;
}

#maxNumber {
  border: 0;
  width: 100px; 
  font-size: 1.5em;
}

.overview {
  font-size: 2em
}

#total {
  width: 80px;
  border: 0;
  font-size: 1em;
}

#configContainer input {
  background-color: transparent;
}

