.fighter {
  left: -20%;
  bottom: 0;
  position: relative;
  transform: scale(1.25);
  height: 6em;
  align-content: end;
}

.fighter img {
  width: 8em;
  height: 5em;
  object-fit: scale-down;
}