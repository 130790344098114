#clockContainer {
  position: relative;
  height: 10em;
  width: 10em;
  background: url(/public/assets/Clock.png) no-repeat;
  /*setting our background image*/
  background-size: 100%;
}

#hour,
#minute,
#second {
  position: absolute;
  background: black;
  border-radius: 10px;
  transform-origin: bottom;
  transition: all .5s;
}

#hour {
  width: 1.8%;
  height: 25%;
  top: 25%;
  left: 48.85%;
  opacity: 0.8;
  background: black;
}

#minute {
  width: 1.6%;
  height: 30%;
  top: 19%;
  left: 48.9%;
  opacity: 0.8;
}

#second {
  width: 1%;
  height: 40%;
  top: 9%;
  left: 49.25%;
  opacity: 0.8;
}