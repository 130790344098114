.pokemonTalk {
  margin-bottom: 6%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
}

.bubble {
  border-radius: 0.6em;
  margin: 0.5em;
  padding: 0.5em 0.8em;
}

.talkHint {
  display: flex;
  width: 27em;
  flex-wrap: wrap;
  text-align: right;
  justify-content: right;
}

.hint {
  background-color: #232323b5;
  color: white;
  width: fit-content;
  border: 1px solid white;
  box-sizing: border-box;
  text-shadow: 1px 1px black;
}

.correct {
  background-color: #87e70887;
}

.talkQuestion {
  width: 24em;
  text-align: right;
  justify-content: right;
  display: flex;
}

.questionBubble,
.questionTalk,
.grammarQuestion {
  background-color: #ffffffd6;
}

.bubble {
  border-radius: 0.6em 0.6em 0 0.6em;
}