.shadowContainer {
  width: fit-content;
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: relative;
}

.shadowContainer img {
  z-index: 1;
  object-fit: none;
  object-position: bottom;
}

.shadow {
  width: 40px;
  background: #00000085;
  height: 10px;
  border-radius: 100%;
  position: relative;
  left: 4px;
  z-index: 0;
  margin: auto;
}