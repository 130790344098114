:root {
  --pokeball-size: 24px;
}

#pokemonContainer {
  position: relative;
  flex-grow: 1;
  align-content: end;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.pokeball {
  position: absolute;
  left: -37em;
  bottom: -5.3em;
  right: 0;
  margin: auto;
  width: var(--pokeball-size);
  height: var(--pokeball-size);
  background: #b71c1c;
  border-radius: 50%;
  box-shadow: inset 0 calc(-.72*var(--pokeball-size)) 0 calc(-.37*var(--pokeball-size)) #fff,
    inset 0 calc(-.76*var(--pokeball-size)) 0 calc(-.35*var(--pokeball-size)) #000, 0 0 0 calc(.05*var(--pokeball-size)) #000;
}

.pokeball::before {
  display: block;
  content: '';
  position: absolute;
  height: 22%;
  width: 22%;
  border-radius: 50%;
  background: #fff;
  top: 48%;
  left: 34%;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: 2px 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 calc(.05*var(--pokeball-size)) #fff, 0 0 0 calc(.1*var(--pokeball-size)) #000;
}

.textQuestion {
  position: relative;
  background: #ffffffd6;
  border-radius: 10px 10px 0px 10px;
  padding: 10px;
  right: 2%;
  bottom: 2em;
  width: 14em;
}

.textLine {
  color: gray;
  font-style: italic;
}

.textLine:last-child {
  color: black;
  font-style: normal;
}

.textContainer {
  /* max-height: 10em;
  overflow-y: auto; */
}

#pokemon {
  transition: all 0.2s;
  transform-origin: center bottom;
  width: fit-content;
  position: relative;
}

#pokemon.pokemonCatching .shadowContainer {
  transform: scale(0);
}

.throwing {
  animation: mymove2 0.6s linear forwards;
  transform-origin: center center;
}

.catching {
  animation: anti-wiggle .5s 3 ease-in-out forwards;
  transform-origin: center 3.2em;
}

@keyframes throwing {
  0% {
    transform: translateY(-600px);
  }

  44% {
    transform: translateY(80px);
  }

  66% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(50px);
  }
}

@keyframes anti-wiggle {
  0% {
    transform: translateY(1px);
    bottom: 1em;
    left: 1em;
    filter: brightness(1.05);
  }

  20% {
    transform: translateX(4px) rotate(-7deg);
  }

  40% {
    transform: translateX(-8px) rotate(14deg);
  }

  60% {
    transform: translateX(2px) rotate(-4deg);
  }

  80% {
    transform: translateX(-1px) rotate(2deg);
  }

  100% {
    transform: translateX(0px) translateY(1px) rotate(0deg);
    bottom: 1em;
    left: 1em;
    filter: brightness(1.25);
  }
}

@keyframes mymove2 {
  0% {
    transform: rotate(0deg);
    bottom: -5.3em;
    left: -37em;
  }

  33% {
    bottom: 2em;
    left: -18em;
  }

  66% {
    bottom: 3em;
    left: -8em;
  }

  100% {
    transform: rotate(1080deg);
    bottom: 1em;
    left: 1em;
  }
}