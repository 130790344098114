body {
  touch-action: none;
  font-size: 16px;
  font-family: 'Playpen Sans', cursive;
  background: url(https://pbs.twimg.com/media/F0wYfBxWYAEhZBR.png:large) no-repeat 45% bottom;
}

#root {
  container-type: inline-size;
}

.app {
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  min-height: 100dvh;
  gap: 1em;
}

input {
  font-family: 'Playpen Sans';
  touch-action: initial !important;
}

#centerContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.footer {
  z-index: 1;
}

.question @media (min-height: 1050px) {
  body {
    background-size: cover;
  }
}

@container (min-width: 40em) {
  .app {
    max-width: 40em;
    margin: auto;
  }
}

@container (min-width: 60em) {
  .app {
    max-width: 100%;
    margin: auto;
  }

  .footer {
    container-type: inline-size;
    display: flex;
    flex-direction: row-reverse;
  }

  .inputPanel {
    width: 60%;
  }

  #pokebag {
    flex-grow: 1;
    height: unset;
  }
}

.move {
  background: url('../public/assets/moves/Animations/grass2.png') no-repeat;
  width: 192px;
  height: 192px;
  background-position: -10000px;
}

.bubble {
  .textLine {
    padding: 0.1em;
  }
}