.slideHolder {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.pokemonsSlide {
  display: inline-block;
  animation: 40s scroll infinite linear;
}

.pokemonSlide {
  display: inline-block;
  margin: 0 10px;
}

.pokemonsSlide img {
  animation: wobble 3s ease-in-out infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%)
  }
}